<template>
  <div class="flex h-full justify-center items-center">
    <vs-dropdown
      class="px-3 w-32 py-2 per_drop_color"
      :style="{background : currentColor()}"
      vs-trigger-click
    >
      <div class="flex justify-between items-center">
        <span>{{ currentStatus() }}</span>
        <feather-icon
          icon="ChevronDownIcon"
          svg-classes="h-5 w-5"
        />
      </div>

      <vs-dropdown-menu class="m-0 w-32">
        <vs-dropdown-item
          class="p-1 m-0 w-32"
          @click="clicked"
          :style="{color : nextColor()}"
        >
          {{ nextStatus() }}
        </vs-dropdown-item>
        <vs-dropdown-item
          v-show="currentStatus() == 'قيد المراجعة'"
          class="p-1 m-0 w-32"
          @click="clicked2"
          style="color : #EF4141;"
        >
          مرفوض
        </vs-dropdown-item>
      </vs-dropdown-menu>
    </vs-dropdown>

    <vs-dropdown
      class="w-32 h-12 per_drop_color"
      :style="{background : currentColor()}"
      v-if="isIconShow2()"
    >
      <div>
        {{ currentStatus() }}
      </div>
    </vs-dropdown>
  </div>
</template>

<script>
import Vue from "vue";
import utilities from "@/app/shared/utilities";

export default Vue.extend({
  name: "AgIcon",
  methods: {
    clicked() {
      this.params.click(this.params.value);
    },
    clicked2() {
      this.params.clickToReject(this.params.value);
    },
    clickedSecondIcon() {
      this.params.clickSecondIcon(this.params.value);
    },
    clickedThirdIcon() {
      this.params.clickThirdIcon(this.params.value);
    },
    currentStatus() {
      let statusObject = utilities.changeOrderStatus(this.params.value.data);
      return statusObject.fromStatus;
    },

    nextStatus() {
      let statusObject = utilities.changeOrderStatus(this.params.value.data);
      return statusObject.toStatus;
    },

    currentColor() {
      return utilities.changeOrderStatus(this.params.value.data, false).fromStatusColor;
    },

    nextColor() {
      return utilities.changeOrderStatus(this.params.value.data, false).toStatusColor;
    },

    isSecondIconShow() {
      const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
      if (this.params.value.data.status === 'Rejected' || this.params.value.data.status === 'Delivered')
        return true;
      else if (this.params.value.data.status !== 'Review')
        return true;
      else if ((this.params.value.colDef.headerName === 'خيارات الطلب') && (roles.includes('super_admin')) || (this.params.value.colDef.headerName === 'خيارات الطلب') && (roles.includes('store_admin')))
        return true;
      else if ((this.params.value.colDef.headerName === 'خيارات المنتج المطلوب') && (roles.includes('supervisor')) || (this.params.value.colDef.headerName === 'خيارات المنتج المطلوب') && (roles.includes('super_admin')))
        return true;
      else return false;
    },
    isIconShow() {
      const roles = JSON.parse(localStorage.getItem('user')).user.roles.map((role) => role.name);
      if (this.params.value.data.status === 'Rejected' || this.params.value.data.status === 'Delivered')
        return true;
      else if ((roles.includes('store_admin')) && (this.params.value.data.status === 'Ready'))
        return true;
      else if ((this.params.value.colDef.headerName === 'خيارات الطلب') && (roles.includes('store_admin')) || (this.params.value.colDef.headerName === 'خيارات الطلب') && (roles.includes('super_admin')))
        return true;
      else if ((this.params.value.colDef.headerName === 'خيارات المنتج المطلوب') && (roles.includes('supervisor')) || (this.params.value.colDef.headerName === 'خيارات المنتج المطلوب') && (roles.includes('super_admin')))
        return true;
      else return false;
    },
    isIconShow2() {
      if (this.params.value.data.status === 'Rejected' || this.params.value.data.status === 'Delivered')
        return true;
    },
  }
});
</script>

<style scoped>
.verticalLine {
  border-left: 1px solid black;
}

h3 {
  text-align: center;
}

.per_drop_color {
  color: white;
  border-radius: 5px;
}
</style>
